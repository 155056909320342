import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, isWeakMap, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, ADMIN_URL, APP_URL, SITE_URL, GET_STAFFS } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup,
  CardTitle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
import VaccinationFormModal from "components/Forms/VaccinationForm"


const libraries =['places'];

const EditStaff = props => {
  //meta title
  document.title="Edit Staff | Tacveiha Panel";
  const inputRef = useRef();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  });

  const params = props.router.params;
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const [ModalLoading, setModalLoading] = useState(false);
  const [ModalTitle, setModalTitle] = useState('');
  const [columns, setColumns] = useState('admin_columns');
  const [ColumnsHide, setColumnsHide] = useState([]);
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [ServiceTypesArray, setServiceTypesArray] = useState([]);
  const [StaffServiceTypes, setStaffServiceTypes] = useState([]);
  const [UserType, setUserType] = useState('');
  const [StaffBuddies, setStaffBuddies] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [Casemanagers, setCasemanagers] = useState([]);
  const [StaffDob, setStaffDob] = useState('');
  const [LanguageSpoken, setLanguageSpoken] = useState([]);
  const [LanguageSpokenDropdown, setLanguageSpokenDropdown] = useState([]);
  
  const [StaffAddress, setStaffAddress] = useState('');
  const [StaffLatLng, setStaffLatLng] = useState('');
  
  const [roster, setRoster] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [TopLoading, setTopLoading] = useState(false);
  
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedDocFiles, setselectedDocFiles] = useState([]);
  const [ShowPicUploadBtn, setShowPicUploadBtn] = useState(false);
  
  const [profileCover, setProfileCover] = useState();

  const [staffTypes, setStaffTypes] = useState('');
  const [BuddiesDropdown, setBuddiesDropdown ] = useState([]);

  const [StaffDepartment, setStaffDepartment] = useState([]);
  const [StaffCasemanager, setStaffCasemanager] = useState([]);
  const [department_dropdown, setDepartment_dropdown] = useState([]);
  const [casemanager_dropdown, setCasemanager_dropdown] = useState([]);


  //all notes
  const [StaffNotes, setStaffNotes] = useState('');
  const [SendSms, setSendSms] = useState(false);
  const [SmsSubject, setSmsSubject ] = useState('');
  const [StaffNotesList, setStaffNotesList ] = useState([]);

  //HR nores
  const [StaffHrNotes, setStaffHrNotes] = useState('');
  const [StaffHrNotesList, setStaffHrNotesList] = useState([]);
  const [HrNotesLoading, setHrNotesLoading] = useState(false);

  //Modals
  const [VaccinationDetailsModal, setVaccinationDetailsModal] = useState(false);
  const [DocumentsModal, setDocumentsModal] = useState(false);
  const [HrDocumentsModal, setHrDocumentsModal] = useState(false);
  const [NotesLoading, setNotesLoading] = useState(false);
  const [AllRosterModal, setAllRosterModal] = useState(false);


  //vaccination details
  const [StaffsVaccinationDetails, setStaffsVaccinationDetails]= useState([]);
  const [VaccinationActivity, setVaccinationActivity] = useState([]);


  //documents details
    const [DocumentsActivity, setDocumentsActivity] = useState([]);
    const [StaffsActiveDocuments, setStaffsActiveDocuments] = useState([]);
    const [StaffsDeletedDocuments, setStaffsDeletedDocuments] = useState([]);
    const [StaffsDocuments, setStaffsDocuments] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);

    const dispatch = useDispatch();

    const selectGroupRostersState = (state) => state.StaffsReducer;
    const groupRostersProperties = createSelector(
    selectGroupRostersState,
        (rosterReducer) => ({
        loading: rosterReducer.loading
        })
    );
    const {
    loading
    } = useSelector(groupRostersProperties);
    

    //hr documents upload
    const [HrDocsDropdown, SetHrDocsDropdown ] = useState([]);
    const [HrDocs, setHrDocs ] = useState([]);
    const [HrDocsIsExpiry, setHrDocsIsExpiry ] = useState('');
    const [HrDocsExpiryDate, setHrDocsExpiryDate ] = useState('');
    


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            user_status : roster && roster.user_status,
            daily_roster_notes_check : ( roster && roster.daily_roster_notes_check ) || 0 ,
            double_book : ( roster && roster.double_book ) || 0,
            for_broadcast_roster : ( roster && roster.for_broadcast_roster ) || 0,
            buddy_id : ( roster && roster.buddy_id ) || "",
            user_type:  roster.user_type  ,
            user_title:  ( roster && roster.user_title ) || "",
            employee_id : ( roster && roster.employee_id ) || "",
            staff_fname : ( roster && roster.staff_fname ) || "",
            staff_lname : ( roster && roster.staff_lname ) || "",
            staff_email : ( roster && roster.staff_email ) || "",
            staff_password : "",
            staff_confirm_password : "",
            staff_gender : ( roster && roster.staff_gender ) || "",
            staff_dob : ( roster && roster.staff_dob ) || "",
            staff_mobile_no : ( roster && roster.staff_mobile_no ) || "",
            staff_home_no : ( roster && roster.staff_home_no ) || "",
            language_spoken : ( roster && roster.language_spoken ) || "",
            staff_address : ( roster && roster.staff_address ) || "",
            staff_latlng : ( roster && roster.staff_latlng ) || "",
            profile_cover : ( roster && roster.profile_cover ) || "",
            staff_emergency_contact_name : ( roster && roster.staff_emergency_contact_name ) || "",
            staff_emergency_contact_relation : ( roster && roster.staff_emergency_contact_relation ) || "",
            staff_emergency_contact_mobile : ( roster && roster.staff_emergency_contact_mobile ) || "",
            staff_emergency_contact_email : ( roster && roster.staff_emergency_contact_email ) || "",
            exclude_sms :  roster && roster.exclude_sms || 0,
            can_delete_rosters :  (roster && roster.can_delete_rosters ) || 0,
            sms_auth :  (roster && roster.sms_auth) || 0,
            email_auth :  (roster && roster.email_auth) || 0,
            auth_email_address:  roster && roster.auth_email_address,
        },
        validationSchema: Yup.object({
            employee_id : Yup.string().required("Required"),
            user_title: Yup.string().required("Required"),
            staff_fname: Yup.string().required("Required"),
            staff_lname: Yup.string().required("Required"),
            staff_email: Yup.string().required("Required"),
            staff_gender: Yup.string().required("Required"),
            staff_mobile_no: Yup.string().required("Required"),
            staff_home_no: Yup.string().required("Required"),
            staff_emergency_contact_name : Yup.string().required("Required"),
            staff_emergency_contact_relation: Yup.string().required("Required"),
            staff_emergency_contact_mobile : Yup.string().required("Required"),
            staff_emergency_contact_email : Yup.string().required("Required"),
    }),
    onSubmit: values => {

        

        if( staffTypes == "staff" ) {
            
            if( StaffServiceTypes.length == 0 ) {
                toast.error("Please select service types", { autoClose: 5000 });
                return false;
            }

            if( StaffBuddies == "" ) {
                toast.error("Please select staff buddy", { autoClose: 5000 });
                return false;
            }


            if( UserType == "" ) {
                toast.error("Please choose For option", { autoClose: 5000 });
                return false;
            }

            if( UserType  == 'contractors') {
    
                if( StaffDepartment == "" ) {
                    toast.error("Please select staff department/ 3rd party contractors", { autoClose: 5000 });
                    return false;
                }
        
                if( StaffCasemanager == "" ) {
                    toast.error("Please select staff case manager / contractor", { autoClose: 5000 });
                    return false;
                }

            }


            // if( values.user_type == "" ) {
            //     toast.error("Please choose user type option", { autoClose: 5000 });
            //     return false;
            // }

            // // console.log(StaffDepartment);
            // // console.log(StaffCasemanager);
    
            // if( StaffDepartment.value == 0 ) {
            //     toast.error("Please select staff department/ 3rd party contractors", { autoClose: 5000 });
            //     return false;
            // }
    
            // if( StaffCasemanager.value == 0 ) {
            //     toast.error("Please select staff case manager / contractor", { autoClose: 5000 });
            //     return false;
            // }
    
        }

        if( StaffDob == "" ) {
          toast.error("Please provide staff date of birth", { autoClose: 5000 });
          return false;
        }

        if( LanguageSpoken == "" ) {
            toast.error("Please select language spoken", { autoClose: 5000 });
            return false;
        }
        
        if( StaffAddress == "" ) {
            toast.error("Please provide staff address", { autoClose: 5000 });
            return false;
        }

        if( values.email_auth == 1 &&  values.auth_email_address == '' ) {
          toast.error("Please provide authentication email address", { autoClose: 5000 });
          return false;
        }

        const newUser = {
            user_id: roster.user_id,
            staff_type: staffTypes,
            user_status : values.user_status,
            daily_roster_notes_check: values.daily_roster_notes_check,
            double_book: values.double_book,
            for_broadcast_roster: values.for_broadcast_roster,
            service_type: StaffServiceTypes,
            buddy_id: StaffBuddies,
            user_type: UserType,
            department_id: StaffDepartment,
            case_manager_id: StaffCasemanager,
            user_title: values.user_title,
            employee_id: values.employee_id,
            staff_fname: values.staff_fname,
            staff_lname: values.staff_lname,
            staff_email: values.staff_email,
            staff_password: values.staff_password,
            staff_dob: StaffDob,
            profile_pic: profileCover,
            staff_gender: values.staff_gender,
            staff_mobile_no: values.staff_mobile_no,
            staff_home_no: values.staff_home_no,
            staff_language: LanguageSpoken,
            staff_address: values.staff_address,
            staff_latlng: StaffLatLng,
            staff_emergency_contact_name: values.staff_emergency_contact_name,
            staff_emergency_contact_relation: values.staff_emergency_contact_relation,
            staff_emergency_contact_mobile: values.staff_emergency_contact_mobile,
            staff_emergency_contact_email: values.staff_emergency_contact_email,
            exclude_sms : values.exclude_sms,
            can_delete_rosters : values.can_delete_rosters,
            sms_auth: values.sms_auth,
            email_auth: values.email_auth,
            auth_email_address: values.auth_email_address,
        };

        // console.log(newUser);

        // return;

        setTopLoading(true);
        axios.post(APP_URL+GET_STAFFS,{'data_for':'edit_staff','formData': newUser, 'user': loggedUser})
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;
            // console.log(fetchedData);
    
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffNotesList(StaffNotesList => [fetchedData.activityLog,...StaffNotesList]);
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
    }
  });

  useEffect(() => {

    const formData = {
        user_id: params.id
    }
    setTopLoading(true);
      axios.post(APP_URL+GET_STAFFS,{'data_for':'get_staff_details','formData': formData,'user': loggedUser})
        .then(res => {
            setTopLoading(false);
          const fetchedData = res.data;
        //   console.log(fetchedData);
  
          if( fetchedData.status == 'success' ) {

            setStaffTypes(fetchedData.userRow.user_role);
            setUserType(fetchedData.userRow.user_type)

            if( fetchedData.userRow.user_role == 'staff' ) {
                
                setServiceTypesArray(fetchedData.serviceTypesDropdown);
                setStaffServiceTypes(fetchedData.ServiceTypes)
                setBuddiesDropdown(fetchedData.buddiesDropdown);
                
                setDepartment_dropdown(fetchedData.departmentDropdown);
                setCasemanager_dropdown(fetchedData.casemangerDropdown);

                SetHrDocsDropdown(fetchedData.HrDocsDropdown);


                if( fetchedData.buddy_name == "" ) {
                    setStaffBuddies({label: 'Select Buddy', value: '0'});
                } else {
                    setStaffBuddies({label: fetchedData.buddy_name, value: fetchedData.buddy_id});
                }

                if( fetchedData.deprtment_name == "" ) {
                    setStaffDepartment({label: 'Select Department', value: '0'});
                } else {
                    setStaffDepartment({label: fetchedData.deprtment_name, value: fetchedData.department_id});
                }
                if( fetchedData.case_manager_name == "" ) {
                    setStaffCasemanager({label: 'Select Case Manager', value: '0'});
                } else {
                    setStaffCasemanager({label: fetchedData.case_manager_name, value: fetchedData.case_manager_id});
                }
                
                
            }

            setStaffAddress(fetchedData.userRow.staff_address);
            setStaffLatLng(fetchedData.userRow.staff_latlng);

            setLanguageSpokenDropdown(fetchedData.languageDropdown);
            setLanguageSpoken(fetchedData.LanguageSpoken);
            setStaffDob(fetchedData.userRow.staff_dob);
            updateRoster(fetchedData.userRow);
            setStaffNotesList(fetchedData.staff_notes);
            setStaffHrNotesList(fetchedData.hr_notes);
            setProfileCover(ADMIN_URL+'uploads/profile_img/'+fetchedData.userRow.profile_pic);
          }
  
          if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
          }
      })

  }, []);


  const updateRoster = (userRow) => {
    setRoster({
        user_status: userRow.user_status,
        user_id: userRow.user_id,
        daily_roster_notes_check : userRow.daily_roster_notes_check,
        double_book : userRow.double_book,
        for_broadcast_roster : userRow.for_broadcast_roster,
        user_title:  userRow.user_title,
        employee_id : userRow.employee_id ,
        // user_type : userRow.user_type,
        staff_fname : userRow.staff_fname ,
        staff_lname : userRow.staff_lname ,
        staff_email : userRow.user_email ,
        staff_gender : userRow.staff_gender,
        staff_dob : userRow.staff_dob,
        staff_mobile_no : userRow.staff_mobile_no,
        staff_home_no : userRow.staff_home_no,
        language_spoken : userRow.language_spoken,
        staff_address : userRow.staff_address,
        staff_latlng : userRow.staff_latlng,
        staff_emergency_contact_name : userRow.staff_emergency_contact_name ,
        staff_emergency_contact_relation : userRow.staff_emergency_contact_relation,
        staff_emergency_contact_mobile : userRow.staff_emergency_contact_mobile,
        staff_emergency_contact_email : userRow.staff_emergency_contact_email,
        exclude_sms : userRow.exclude_sms,
        can_delete_rosters : userRow.can_delete_rosters,
        sms_auth : userRow.sms_auth,
        email_auth : userRow.email_auth,
        auth_email_address: userRow.auth_email_address,
        double_book: userRow.double_book
    });
    
  }

  useEffect(() => {

  },[roster]);

  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        validation.values.staff_address = place.formatted_address;
        setStaffAddress(place.formatted_address);
        setStaffLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());
    }
  }

  const handleCheckboxChange = (event) => {

    const checkedId = parseInt(event.target.value);
    const isChecked = event.target.checked;
    if( event.target.checked ) {
        setStaffServiceTypes([...StaffServiceTypes, checkedId]);
    } else{
        setStaffServiceTypes( (StaffServiceTypes) => {
            return StaffServiceTypes.filter((id) =>{
                return id !== checkedId
            })
        });
    }

  }

  const handleUserType = (type) => {

    // validation.values.user_type = type;

    const formData = {
        type: type
    }
    setUserType(type);
    setTopLoading(true);
    
    setStaffDepartment([]);
    setStaffCasemanager([]);
    setDepartment_dropdown([]);
    setCasemanager_dropdown([]);

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const handleDepartmentChange = (e) => {
    setStaffDepartment(e);
    const formData = {
        dep_id: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManager','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setCasemanager_dropdown(res.data.casemanagers);
    })
  }

  const handleSendSms = (event) => {
    const sendSms = event.target.value;
    if( event.target.checked ) {
        setSendSms(true);
    } else{
        setSendSms(false);
    }
  };

  const addStaffNotes = () => {

    if( SendSms ) {
        
        if( SmsSubject == '' ) {
            toast.error("Please provide sms subject", { autoClose: 5000 });
            return false;
        }

    }

    if( StaffNotes == '' ) {
        toast.error("Please add notes", { autoClose: 5000 });
        return false;
      }
  
    const notesForm = {
        ros_id: params.id,
        notes: StaffNotes,
        activity_for: 'staff',
        sendSms: SendSms,
        sms_subject: SmsSubject,
        smsType: 'staff',
    };

    setNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': notesForm,'user': loggedUser})
        .then(res => {
            setNotesLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                setStaffNotes('');
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffNotesList(StaffNotesList => [fetchedData.data,...StaffNotesList]);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
  }

  //Hr Notes

  const addStaffHrNotes = () => {

    if( StaffHrNotes == '' ) {
        toast.error("Please add notes", { autoClose: 5000 });
        return false;
      }
  
    const notesForm = {
        ros_id: params.id,
        notes: StaffHrNotes,
        activity_for: 'staff',
        role: loggedUser.role,
    };

    setHrNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': notesForm,'user': loggedUser})
        .then(res => {
            setHrNotesLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                setStaffHrNotes('');
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffHrNotesList(StaffHrNotesList => [fetchedData.data,...StaffHrNotesList]);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
  }


  const handleRadioChange = e => {
    // console.log(e.target.value);
  }


  const toggleVaccinationModal = () => {
    if (VaccinationDetailsModal) {
      setVaccinationDetailsModal(false);
    } else {
        setVaccinationDetailsModal(true);
    }
  };

  const toggleDocumentsModal = () => {
    if (DocumentsModal) {
      setDocumentsModal(false);
    } else {
        setDocumentsModal(true);
    }
  };


  const vaccination_columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination For",
        accessor: "vaccination_for",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination For",
        accessor: "type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination Date",
        accessor: "vaccination_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination Certificate",
        Cell: cellProps => {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                      <Link
                          to="#"
                          className="btn btn-sm btn-soft-info"
                          onClick={() => {
                              const userData = cellProps.row.original;
                              handleViewCertificate(userData);
                          }}
                          id={`downloadtooltip-${cellProps.row.original.id}`}
                      >
                          <i className="dripicons-download" />
                          <UncontrolledTooltip placement="top" target={`downloadtooltip-${cellProps.row.original.id}`} >
                              Download
                          </UncontrolledTooltip>
                      </Link>
                  </li>
              </ul>
            );
          },
      },
    ],
    []
  );

  const admin_columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination For",
        accessor: "vaccination_for",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination For",
        accessor: "type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination Date",
        accessor: "vaccination_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Vaccination Certificate",
        Cell: cellProps => {
            return (
              <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                      <Link
                          to="#"
                          className="btn btn-sm btn-soft-info"
                          onClick={() => {
                              const userData = cellProps.row.original;
                              handleViewCertificate(userData);
                          }}
                          id={`downloadtooltip-${cellProps.row.original.id}`}
                      >
                          <i className="dripicons-download" />
                          <UncontrolledTooltip placement="top" target={`downloadtooltip-${cellProps.row.original.id}`} >
                              Download
                          </UncontrolledTooltip>
                      </Link>
                  </li>
              </ul>
            );
          },
      },
    ],
    []
  );



  const doc_columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        enableHiding: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        enableHiding: false,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            if( userData.doc_status != 5 ) {
                                handleArchiveDoc(userData);
                            }
                        }}
                        id={`viewprofiletooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-archive" />
                        <UncontrolledTooltip placement="top" target={`viewprofiletooltip-${cellProps.row.original.id}`} >
                            Archive
                        </UncontrolledTooltip>
                    </Link>
                </li>

                {/* <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleDownloadDoc(userData);
                        }}
                        id={`downloadtooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-download" />
                        <UncontrolledTooltip placement="top" target={`downloadtooltip-${cellProps.row.original.id}`} >
                            Download
                        </UncontrolledTooltip>
                    </Link>
                </li> */}

                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleRemoveDoc(userData);
                        }}
                        id={`removetooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-trash"/>
                        <UncontrolledTooltip placement="top" target={`removetooltip-${cellProps.row.original.id}`} >
                            Remove
                        </UncontrolledTooltip>
                    </Link>
                </li>

            </ul>
          );
        },
      },
    ],
    []
  );

  const doc_columns_deleted = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        // enableHiding: loggedUser.role === 'admin' ? true : false,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    ],
    []
  );

    const StaffsAllRostersBtn = () => {
        
    }

    const StaffsVaccinationsBtn = () => {
        toggleVaccinationModal();
        // setModalLoading(true);
        // const formData = {
        //     uid: params.id,
        //     type: 'client_vaccination'
        // };
        // axios.post(APP_URL+GET_STAFFS,{'data_for':'get_staff_vaccination_data','formData': formData,'user': loggedUser})
        // .then(res => {
        //     setModalLoading(false);
        //     const fetchedData = res.data;

        //     if( fetchedData.status == 'success' ) {
        //         setStaffsVaccinationDetails(fetchedData.VacDetetail);
        //     }

        //     if( fetchedData.status == 'error' ) {
        //     toast.error(fetchedData.html, { autoClose: 5000 });
        //     }
        // })
    }

    const StaffsDocumentsBtn = () => {

        setModalTitle('All Documents');

        setColumnsHide([]);
        if( loggedUser.role == 'human_resource') {
            //setColumnsHide(ColumnsHide => [...ColumnsHide, 'doc_added']);
        }

        setColumns(doc_columns);

        toggleDocumentsModal();
        setModalLoading(true);
        const formData = {
            user_id: params.id,
            type: 'staff_doc',
            doc_by: ''
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'get_clients_documents','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                
                setStaffsActiveDocuments(fetchedData.documents_active);
                setStaffsDeletedDocuments(fetchedData.documents_deleted);
                setStaffsDocuments(fetchedData.documents_active);
                setDocumentsActivity(fetchedData.docs_activity);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
        
    }

    const StaffsHRDocumentsBtn = () => {
        
        setModalTitle('HR Documents');
        
        setColumnsHide([]);
        if( loggedUser.role == 'human_resource') {
            //setColumnsHide(ColumnsHide => [...ColumnsHide, 'doc_added']);
        }
       
        setColumns(doc_columns);

        toggleDocumentsModal();
        setModalLoading(true);
        const formData = {
            user_id: params.id,
            type: 'staff_doc',
            doc_by: 'human_resource'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'get_clients_documents','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                
                setStaffsActiveDocuments(fetchedData.documents_active);
                setStaffsDeletedDocuments(fetchedData.documents_deleted);
                setStaffsDocuments(fetchedData.documents_active);
                setDocumentsActivity(fetchedData.docs_activity);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
        
    }


    const handleShowDocuments = (type) => {
        // console.log(type);
        if( type == 'active' ) {
            setColumns(doc_columns);
            setStaffsDocuments(StaffsActiveDocuments);
            
            // if( loggedUser.role == 'admin' ) {
            //     setColumnsHide(['Expiry Date']);
            // }

            // if ( loggedUser.role == 'human_resource' ){
            //     setColumnsHide([]);
            // }
        }
        if( type == 'deleted') {
            // columns = hr_columns_deleted;
            setColumns(doc_columns_deleted);
            setStaffsDocuments(StaffsDeletedDocuments);
            
            // if( loggedUser.role == 'admin' ) {
            //     setColumnsHide(['Expiry Date','Action']);
            // }

            // if ( loggedUser.role == 'human_resource' ){
            //     setColumnsHide(['Action'])
            // }
            
        }
    }

    const handleArchiveDoc = (docs) => {
        setModalLoading(true);
        const formData = {
            doc_id: docs.id,
            type: 'staff_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'archive_doc','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffsDocuments((StaffsDocuments) => StaffsDocuments.filter(data => data.id.toString() !==  docs.id.toString()));
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })
    }

    const handleRemoveDoc = (docs) => {


        setModalLoading(true);
        const formData = {
            doc_id: docs.id,
            type: 'staff_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'remove_doc','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffsDocuments((StaffsDocuments) => StaffsDocuments.filter(data => data.id.toString() !==  docs.id.toString()));
                
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })
    }


    const handleDownloadDoc = (docs) => {
        window.open(ADMIN_URL+'download_file.php?name='+docs.doc_name+'&user_id='+params.id, '_blank', 'noopener,noreferrer');
    }

    const handleViewCertificate = (rows) => {
        if( rows.vaccination_certi ) {
            window.open(SITE_URL+rows.vaccination_certi, '_blank', 'noopener,noreferrer');
        } else {
            toast.error('Certicifate file not available.', { autoClose: 5000 });
        }
    }

    //dropzone upload files

    const handleHrIsExpiry = e => {
        // console.log(e.target.value);
        // console.log(e.target.checked);
        if( e.target.checked ) {
            setHrDocsIsExpiry(e.target.value);
        }
    }

    const handleUploadDoc = (files) => {

        files.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            })
        )
        
        setselectedDocFiles(files);

        const formData = new FormData();

        files.forEach((file) => {
            formData.append('file', file);
        });

        // files.forEach((file) => {
        //     console.log(file);
        //     formData.append('files[]', file);
        // });

        formData.append('data_for', 'staff_doc');
        formData.append('user_id', params.id);
        formData.append('user_type', 'staff_doc');
        formData.append('is_expiry', '');
        formData.append('expiry_date', '');
        formData.append('doc_list_id', '');
        formData.append('doc_list_name', '');
        formData.append('added_by', loggedUser.uid);
        formData.append('role', loggedUser.role);

        setModalLoading(true);

        axios.post(APP_URL+'/upload_files.php',formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setselectedDocFiles([]);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })
    }

    function handleAcceptedFiles(files) {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        )
        setselectedFiles(files);
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    const handleHrDocuments = () => {

        if( HrDocs.length == 0  ) {
            toast.error('Please select Hr document');
            return;
        }

        if( HrDocsIsExpiry == '') {
            toast.error('Please select expiry option');
            return;
        }

        if( HrDocsIsExpiry == 1 && HrDocsExpiryDate == '' ) {
            toast.error('Please provide document expiry date');
            return;
        }

        const formData = new FormData();

        selectedFiles.forEach((file) => {
            formData.append('file', file);
        });
        formData.append('data_for', 'staff_hr_doc');
        formData.append('user_id', params.id);
        formData.append('user_type', 'staff_doc');
        formData.append('is_expiry', HrDocsIsExpiry);
        formData.append('expiry_date', HrDocsExpiryDate);
        formData.append('doc_list_id', HrDocs.value);
        formData.append('doc_list_name', HrDocs.label);
        formData.append('added_by', loggedUser.uid);
        formData.append('role', loggedUser.role);

        setTopLoading(true);

        axios.post(APP_URL+'/upload_files.php',formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setHrDocsIsExpiry(0);
                setHrDocsExpiryDate('');
                setHrDocs([]);
                setselectedFiles([]);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })

    }

    //upload profile pic
    const handleCoverChange = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setProfileCover(base64);
        setShowPicUploadBtn(true);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
        })
    }

    const handleProfileImageUplaod = () => {

        setTopLoading(true);
        const formData = {
            doc_id: roster.user_id,
            type: 'staff',
            profile_pic: profileCover
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'upload_profile_pic','formData': formData,'user': loggedUser})
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })

    }
    

  return (
    <React.Fragment>
      {
        TopLoading ? <TopProgressBar loading/>
        : ''
      }
      <div className="page-content">
        <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Staffs" breadcrumbItem=" Edit Staff" />

            <Row className="mb-6">
                <Col sm="12">
                    <div className="text-sm-end">
                        { roster.user_role == 'staff' && 
                        <>
                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={StaffsAllRostersBtn}
                        disabled={TopLoading}
                        >
                        
                        All Rosters
                        </Button>

                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={StaffsVaccinationsBtn}
                        disabled={TopLoading}
                        >
                        
                        Vaccination Details
                        </Button>
                        </>
                        }

                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={StaffsDocumentsBtn}
                        disabled={TopLoading}
                        >
                        All Documents
                        </Button>
                        {/* { roster.user_role == 'staff' && 
                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={StaffsHRDocumentsBtn}
                        disabled={TopLoading}
                        >
                        HR Documents
                        </Button>
                        } */}
                    </div>
                </Col>
            </Row>

            {/* All Modals */}

            {/* Vaccination details modals */}

            <Modal isOpen={VaccinationDetailsModal} toggle={toggleVaccinationModal} size="xl" backdrop="static" scrollable>
                <ModalHeader toggle={toggleVaccinationModal} tag="h4">
                    Vaccination Details
                </ModalHeader>
                <ModalBody style={{ minHeight: '400px' }}>
                    <VaccinationFormModal staffID={params.id} />
                    {/* {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                        
                        <Col xs={8}>

                            <TableContainer
                                columns={columns}
                                data={StaffsVaccinationDetails}
                                isGlobalFilter={true}
                                showSearchBtn={true}
                                customPageSize={50}
                                className="custom-header-css"
                                // columnsHide={ColumnsHide}
                            />

                        </Col>

                        <Col xs={4}>

                            {/* <Row>
                                <div className="order_notes_div">
                                    <CardTitle>Activity Log</CardTitle>
                                    <ul className="order_notes">
                                        { VaccinationActivity.map(activity => (
                                        <li key={activity.id} rel={activity.id} className="note">
                                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                            <strong>
                                            {activity.activity_by}
                                            </strong>
                                            </abbr></p>
                                        </li>
                                        ))}
                                    </ul>
                                
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    } 
                    <br/>
                    <br/>
                    <br/>*/}
                </ModalBody>
            </Modal>

            {/* Documents modals */}

            <Modal isOpen={DocumentsModal} toggle={toggleDocumentsModal} size="xl" backdrop="static" scrollable>
                <ModalHeader toggle={toggleDocumentsModal} tag="h4">
                    {ModalTitle}
                </ModalHeader>
                <ModalBody style={{ minHeight: '400px' }}>
                    {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                        <Col xs={8}>
                        { loggedUser.role == 'human_resource' }
                            <div className="d-inline-flex">
                                <Button
                                type="button"
                                color="primary"
                                className="mb-2 me-2"
                                onClick={() => handleShowDocuments('active')}
                                >
                                
                                Active
                                </Button>
                            </div>
                            <div className="d-inline-flex">
                                <Button
                                type="button"
                                color="primary"
                                className="mb-2 me-2"
                                onClick={() =>handleShowDocuments('deleted')}
                                >
                                
                                Deleted
                                </Button>
                            </div>
                        </Col>
                        <Col xs={8}>

                            <TableContainer
                                columns={columns}
                                data={StaffsDocuments}
                                isGlobalFilter={true}
                                showSearchBtn={true}
                                customPageSize={50}
                                className="custom-header-css"
                                columnsHide={ColumnsHide}
                            />

                        </Col>

                        <Col xs={4}>

                            <Row>
                                <div className="order_notes_div">
                                    <CardTitle>Activity Log</CardTitle>
                                    <ul className="order_notes">
                                        { DocumentsActivity.map(activity => (
                                        <li key={activity.id} rel={activity.id} className="note">
                                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                            <strong>
                                            {activity.activity_by}
                                            </strong>
                                            </abbr></p>
                                        </li>
                                        ))}
                                    </ul>
                                
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    }
                    <br/>
                    <br/>
                    <br/>
                </ModalBody>
            </Modal>


            <Row>
                <Col lg="8">
                <Card>
                    <CardBody>
                    {/* {
                        loading ? <Spinners setLoading={setLoading} />
                        :
                    <> */}
                    <Form
                            onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                            }}
                        >

                        <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Account Status
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="user_status"
                                type="radio"
                                onClick={validation.handleChange}
                                value='1'
                                checked={validation.values.user_status == 1 ? true: false}
                            /> &nbsp;&nbsp;Active
                            &nbsp;&nbsp;<Input
                                name="user_status"
                                type="radio"
                                onClick={validation.handleChange}
                                value='0'
                                checked={validation.values.user_status == 0 ? true: false}
                            />&nbsp;&nbsp;InActive
                            
                        </Col>

                        </Row>

                        { staffTypes == 'staff' ? 
                        <>

                        <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Daily Roster Notes Check
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="daily_roster_notes_check"
                                type="radio"
                                onClick={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.daily_roster_notes_check &&
                                    validation.errors.daily_roster_notes_check
                                    ? true
                                    : false
                                }
                                checked={validation.values.daily_roster_notes_check == 1 ? true: false}
                            /> &nbsp;&nbsp;Yes
                            &nbsp;&nbsp;<Input
                                name="daily_roster_notes_check"
                                type="radio"
                                onClick={validation.handleChange}
                                value='0'
                                invalid={
                                validation.touched.daily_roster_notes_check &&
                                    validation.errors.daily_roster_notes_check
                                    ? true
                                    : false
                                }
                                checked={validation.values.daily_roster_notes_check == 0 ? true: false}
                            />&nbsp;&nbsp;No
                            {validation.touched.daily_roster_notes_check &&
                            validation.errors.daily_roster_notes_check ? (
                            <FormFeedback type="invalid">
                            {validation.errors.daily_roster_notes_check}
                            </FormFeedback>
                            ) : null}
                        </Col>

                        </Row>

                        <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Provision to Double Book
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="double_book"
                                type="radio"
                                onClick={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.double_book &&
                                    validation.errors.double_book
                                    ? true
                                    : false
                                }
                                checked={validation.values.double_book == 1 ? true: false}
                            /> &nbsp;&nbsp;Yes
                            &nbsp;&nbsp;<Input
                                name="double_book"
                                type="radio"
                                onClick={validation.handleChange}
                                value='0'
                                invalid={
                                validation.touched.double_book &&
                                    validation.errors.double_book
                                    ? true
                                    : false
                                }
                                checked={validation.values.double_book == 0 ? true: false}
                            />&nbsp;&nbsp;No
                            {validation.touched.double_book &&
                            validation.errors.double_book ? (
                            <FormFeedback type="invalid">
                            {validation.errors.double_book}
                            </FormFeedback>
                            ) : null}
                        </Col>

                        </Row>

                        <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            For Broadcast Rosters
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="for_broadcast_roster"
                                type="radio"
                                onClick={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.for_broadcast_roster &&
                                    validation.errors.for_broadcast_roster
                                    ? true
                                    : false
                                }
                                checked={validation.values.for_broadcast_roster == 1 ? true: false}
                            /> &nbsp;&nbsp;Yes
                            &nbsp;&nbsp;<Input
                                name="for_broadcast_roster"
                                type="radio"
                                onClick={validation.handleChange}
                                value='0'
                                invalid={
                                validation.touched.for_broadcast_roster &&
                                    validation.errors.for_broadcast_roster
                                    ? true
                                    : false
                                }
                                checked={validation.values.for_broadcast_roster == 0 ? true: false}
                            />&nbsp;&nbsp;No
                            {validation.touched.for_broadcast_roster &&
                            validation.errors.for_broadcast_roster ? (
                            <FormFeedback type="invalid">
                            {validation.errors.for_broadcast_roster}
                            </FormFeedback>
                            ) : null}
                        </Col>

                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Service Types
                            </Label>
                            <Col sm={9}>

                            {/* <CheckboxComponent options={ServiceTypesArray}></CheckboxComponent> */}
                            {/* {console.log(checkedItems)} */}
                                {ServiceTypesArray.map(item => (
                                    <div key={item.value} className="d-inline-flex">
                                    <label>
                                        <Input
                                        type="checkbox"
                                        value={item.value}
                                        name="service_type"
                                        defaultChecked={StaffServiceTypes.includes(item.value)}
                                        onChange={(event) => handleCheckboxChange(event)}
                                        />
                                        &nbsp;&nbsp;
                                        {item.label}
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    
                                ))}

                            </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Select Buddy
                                </Label>
                                <Col sm={9}>
                                    <Select
                                        
                                        value={StaffBuddies}
                                        name="buddy_id"
                                        options={BuddiesDropdown}
                                        onChange={(e) => setStaffBuddies(e)}
                                        className="select2-selection"
                                        defaultValue={StaffBuddies}
                                        styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </Col>
                            </Row>

                            {/* { validation.values.user_type } */}
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    For
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="user_type"
                                        type="radio"
                                        onChange={(e) => {
                                            handleUserType('provider');
                                        }}
                                        value="provider"
                                        checked={ UserType=== "provider" ? true : false }
                                        invalid={
                                            validation.touched.user_type &&
                                            validation.errors.user_type
                                            ? true
                                            : false
                                        }
                                    /> &nbsp;&nbsp;Provider
                                    &nbsp;&nbsp;<Input
                                        name="user_type"
                                        type="radio"
                                        onChange={(e) => {
                                            handleUserType('contractors');
                                        }}
                                        value="contractors"
                                        checked={ UserType === "contractors" ? true : false }
                                        invalid={
                                            validation.touched.user_type &&
                                            validation.errors.user_type
                                            ? true
                                            : false
                                        }
                                    
                                    />&nbsp;&nbsp;3rd Party Contractors
                                    {validation.touched.user_type &&
                                    validation.errors.user_type ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.user_type}
                                    </FormFeedback>
                                    ) : null}
                                    
                                </Col>
                                
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Staff Department/ 3rd Party Contractor
                                </Label>
                                <Col sm={9}>
                                <Select
                                    value={StaffDepartment}
                                    name="staff_department"
                                    options={department_dropdown}
                                    onChange={(e) => handleDepartmentChange(e)}
                                    className="select2-selection"
                                    defaultValue={department_dropdown}
                                />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Staff Case Manager/ Contractor
                                </Label>
                                <Col sm={9}>
                                <Select
                                    value={StaffCasemanager}
                                    name="staff_department"
                                    options={casemanager_dropdown}
                                    onChange={(e) => setStaffCasemanager(e)}
                                    className="select2-selection"
                                    defaultValue={casemanager_dropdown}
                                />
                                </Col>
                            </Row>

                            </>
                            : null }

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Employeed ID
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="employee_id"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.employee_id}
                                    invalid={
                                    validation.touched.employee_id &&
                                        validation.errors.employee_id
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.employee_id &&
                                    validation.errors.employee_id ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.employee_id}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>
                            
                            <Row className="mb-4">

                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Title
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="user_title"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='Mr'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title == 'Mr' ? true: false}
                                    /> &nbsp;&nbsp;Mr
                                    &nbsp;&nbsp;<Input
                                        name="user_title"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='Mrs'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title == 'Mrs' ? true: false}
                                    />&nbsp;&nbsp;Mrs
                                    &nbsp;&nbsp;<Input
                                        name="user_title"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='Miss'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title == 'Miss' ? true: false}
                                    />&nbsp;&nbsp;Miss
                                    &nbsp;&nbsp;<Input
                                        name="user_title"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='Ms'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title == 'Ms' ? true: false}
                                    />&nbsp;&nbsp;Ms
                                    {validation.touched.user_title &&
                                    validation.errors.user_title ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.user_title}
                                    </FormFeedback>
                                    ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    First Name
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_fname"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_fname}
                                    invalid={
                                    validation.touched.staff_fname &&
                                        validation.errors.staff_fname
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_fname &&
                                    validation.errors.staff_fname ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_fname}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Last Name
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_lname"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_lname}
                                    invalid={
                                    validation.touched.staff_lname &&
                                        validation.errors.staff_lname
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_lname &&
                                    validation.errors.staff_lname ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_lname}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Staff Email
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_email}
                                    invalid={
                                        validation.touched.staff_email &&
                                        validation.errors.staff_email
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_email &&
                                    validation.errors.staff_email ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_email}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>
                            { !!isEdit ? 
                            <>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Password
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_password"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_password}
                                    invalid={
                                        validation.touched.staff_password &&
                                        validation.errors.staff_password
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_password &&
                                    validation.errors.staff_password ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_password}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Re-type Password
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_confirm_password"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_confirm_password}
                                    invalid={
                                        validation.touched.staff_confirm_password &&
                                        validation.errors.staff_confirm_password
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_confirm_password &&
                                    validation.errors.staff_confirm_password ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_confirm_password}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>
                            
                            </> : null }

                            <Row className="mb-4">

                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Staff Gender
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="staff_gender"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='Male'
                                        invalid={
                                            validation.touched.staff_gender &&
                                            validation.errors.staff_gender
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.staff_gender == 'Male' ? true : false }
                                    /> &nbsp;&nbsp;Male
                                    &nbsp;&nbsp;<Input
                                        name="staff_gender"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='Female'
                                        invalid={
                                            validation.touched.staff_gender &&
                                            validation.errors.staff_gender
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.staff_gender == 'Female' ? true : false }
                                    />&nbsp;&nbsp;Female
                                    
                                    {validation.touched.staff_gender &&
                                    validation.errors.staff_gender ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_gender}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Staff DOB
                                </Label>
                                <Col sm={9}>
                                    <InputGroup>
                                    <Flatpickr
                                        name="staff_dob"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setStaffDob(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_dob || StaffDob}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        //   defaultDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                    </InputGroup>
                                    
                                </Col>

                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Mobile No
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_mobile_no"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_mobile_no}
                                    invalid={
                                    validation.touched.staff_mobile_no &&
                                        validation.errors.staff_mobile_no
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_mobile_no &&
                                    validation.errors.staff_mobile_no ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_mobile_no}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                                
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Home Contact No
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_home_no"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_home_no}
                                    invalid={
                                    validation.touched.staff_home_no &&
                                        validation.errors.staff_home_no
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_home_no &&
                                    validation.errors.staff_home_no ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_home_no}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>


                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Language Spoken
                                </Label>
                                <Col sm={9}>
                                    <Select
                                        isMulti={true}
                                        value={LanguageSpoken}
                                        name="language_spoken"
                                        options={LanguageSpokenDropdown}
                                        onChange={(e) => setLanguageSpoken(e)}
                                        className="select2-selection"
                                        defaultValue={LanguageSpoken}
                                        styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Address
                                </Label>
                                <Col sm={9}>

                                <StandaloneSearchBox
                                    onLoad={ref => inputRef.current = ref}
                                    onPlacesChanged={handlePlaceChanged}
                                >
                                    <input
                                        name="staff_address"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Location"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={ validation.values.staff_address }
                                    />
                                </StandaloneSearchBox>
                                </Col>
                                {validation.touched.staff_address &&
                                    validation.errors.staff_address ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_address}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Profile Cover
                                </Label>
                                <Col sm={9}>
                                <Input
                                    type="file"
                                    onChange={handleCoverChange}
                                    accept='.jpg, .png, .jpeg|image/*'
                                />
                                <img src={profileCover}  height={110}/>

                                { ShowPicUploadBtn && 

                                    <div className="text-end">
                                    <button
                                        type="btn"
                                        className="btn btn-success save-user"
                                        disabled={TopLoading}
                                        onClick={handleProfileImageUplaod}
                                    >
                                        Upload Image
                                    </button>
                                    </div>

                                }
                                
                                </Col>
                            
                            </Row>

                            <CardTitle>Emergency Contact</CardTitle>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Name
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_name}
                                    invalid={
                                    validation.touched.staff_emergency_contact_name &&
                                        validation.errors.staff_emergency_contact_name
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.staff_emergency_contact_name &&
                                    validation.errors.staff_emergency_contact_name ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_emergency_contact_name}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Relation
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="staff_emergency_contact_relation"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_emergency_contact_relation}
                                        invalid={
                                            validation.touched.staff_emergency_contact_relation &&
                                            validation.errors.staff_emergency_contact_relation
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.staff_emergency_contact_relation &&
                                    validation.errors.staff_emergency_contact_relation ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_emergency_contact_relation}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Mobile No
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="staff_emergency_contact_mobile"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_emergency_contact_mobile}
                                        invalid={
                                            validation.touched.staff_emergency_contact_mobile &&
                                            validation.errors.staff_emergency_contact_mobile
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.staff_emergency_contact_mobile &&
                                    validation.errors.staff_emergency_contact_mobile ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_emergency_contact_mobile}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Email
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="staff_emergency_contact_email"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_emergency_contact_email}
                                        invalid={
                                            validation.touched.staff_emergency_contact_email &&
                                            validation.errors.staff_emergency_contact_email
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.staff_emergency_contact_email &&
                                    validation.errors.staff_emergency_contact_email ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.staff_emergency_contact_email}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            { staffTypes == 'staff' ?
                                <>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Sms Notification
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="exclude_sms"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='1'
                                        invalid={
                                            validation.touched.exclude_sms &&
                                            validation.errors.exclude_sms
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.exclude_sms == 1 ? true: false}
                                    /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                        <Input
                                        name="exclude_sms"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='0'
                                        //   invalid={
                                        //   validation.touched.exclude_sms &&
                                        //       validation.errors.exclude_sms
                                        //       ? true
                                        //       : false
                                        //   }
                                        checked={validation.values.exclude_sms == 0 ? true: false}
                                        />&nbsp;&nbsp;No
                                    {/* {validation.touched.exclude_sms &&
                                    validation.errors.exclude_sms ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.exclude_sms}
                                    </FormFeedback>
                                    ) : null} */}
                                </Col>
                                    
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Enable Delete Roster
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="can_delete_rosters"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='1'
                                        invalid={
                                            validation.touched.can_delete_rosters &&
                                            validation.errors.can_delete_rosters
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.can_delete_rosters == 1 ? true: false}
                                    /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                        <Input
                                        name="can_delete_rosters"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='0'
                                        //   invalid={
                                        //   validation.touched.exclude_sms &&
                                        //       validation.errors.exclude_sms
                                        //       ? true
                                        //       : false
                                        //   }
                                        checked={validation.values.can_delete_rosters == 0 ? true: false}
                                        />&nbsp;&nbsp;No
                                    {/* {validation.touched.exclude_sms &&
                                    validation.errors.exclude_sms ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.exclude_sms}
                                    </FormFeedback>
                                    ) : null} */}
                                </Col>
                                    
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Sms Authentication
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            name="sms_auth"
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value='1'
                                            invalid={
                                                validation.touched.sms_auth &&
                                                validation.errors.sms_auth
                                                ? true
                                                : false
                                            }
                                            checked={validation.values.sms_auth == 1 ? true: false}
                                        /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                        <Input
                                        name="sms_auth"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='0'
                                        invalid={
                                        validation.touched.sms_auth &&
                                            validation.errors.sms_auth
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.sms_auth == 0 ? true: false}
                                        />&nbsp;&nbsp;No
                                        {validation.touched.sms_auth &&
                                            validation.errors.sms_auth ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.sms_auth}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                    
                                </Row>
                                </>
                            : null }

                            { staffTypes == 'human_resource' ? 
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Sms Authentication
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            name="sms_auth"
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value='1'
                                            invalid={
                                                validation.touched.sms_auth &&
                                                validation.errors.sms_auth
                                                ? true
                                                : false
                                            }
                                            checked={validation.values.sms_auth == 1 ? true: false}
                                        /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                        <Input
                                        name="sms_auth"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='0'
                                        invalid={
                                        validation.touched.sms_auth &&
                                            validation.errors.sms_auth
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.sms_auth == 0 ? true: false}
                                        />&nbsp;&nbsp;No
                                        {validation.touched.sms_auth &&
                                            validation.errors.sms_auth ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.sms_auth}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                    
                                </Row>
                            : null }
                            
                            { staffTypes == 'subadmin' &&
                            <>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Sms Authentication
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            name="sms_auth"
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value='1'
                                            checked={validation.values.sms_auth == 1 ? true : false}
                                        /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                        <Input
                                            name="sms_auth"
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value='0'
                                            checked={validation.values.sms_auth == 0 ? true : false}
                                        />&nbsp;&nbsp;No
                                        
                                    </Col>
                                    
                                </Row>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Email Authentication
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="email_auth"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='1'
                                        checked={validation.values.email_auth == 1 ? true: false}
                                    /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                    <Input
                                        name="email_auth"
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value='0'
                                        checked={validation.values.email_auth == 0 ? true: false}
                                    />&nbsp;&nbsp;No
                                    
                                    </Col>
                                    
                                </Row>

                                {  validation.values.email_auth == 1  && (
                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Provide Authentication Email
                                        </Label>
                                        <Col sm={9}>
                                        <Input
                                                name="auth_email_address"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.auth_email_address}
                                            />
                                            
                                        </Col>

                                    </Row>
                                )}
                            
                            </>
                            }

                            <Row>
                            <Col>
                                <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={TopLoading}
                                >
                                    Update
                                </button>
                                </div>
                            </Col>
                            </Row>
                        </Form>
                        
                    {/* </>
                    } */}
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <CardTitle>Upload Documents</CardTitle>
                    <Dropzone 
                      onDrop={acceptedFiles => {
                        handleUploadDoc(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>

                    </CardBody>
                </Card>

                <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedDocFiles.map((f, i) => {
                        // console.log(f);
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                {f.type != 'application/pdf' ?
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                  : null }
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>

                {/* { roster.user_type == 'staff' && 
                <>
                <Card>
                    <CardBody>
                        <CardTitle>Upload HR Documents</CardTitle>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        // console.log(f);
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                {f.type != 'application/pdf"' ?
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                  : null }
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                        Document Name
                        </Label>
                        <Col sm={9}>
                            <Select
                                
                                value={HrDocs}
                                name="hr_doc_name"
                                options={HrDocsDropdown}
                                onChange={(e) => setHrDocs(e)}
                                className="select2-selection"
                                defaultValue={HrDocs}
                                styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Is Expiry?
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="hr_docs_is_expiry"
                                type="radio"
                                onChange={(e) => handleHrIsExpiry(e) }
                                value='1'
                                // defaultChecked={HrDocsIsExpiry == 1 ? true: false}
                            /> &nbsp;&nbsp;Yes
                            &nbsp;&nbsp;
                            <Input
                                name="hr_docs_is_expiry"
                                type="radio"
                                onChange={(e) => handleHrIsExpiry(e) }
                                value='0'
                            // defaultChecked={HrDocsIsExpiry == 0 ? true: false}
                            />&nbsp;&nbsp;No
                            
                        </Col>
                        
                    </Row>


                    { HrDocsIsExpiry == 1  &&  (
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Expiry Date
                        </Label>
                        <Col sm={9}>
                            <InputGroup>
                            <Flatpickr
                                name="hr_docs_expiry_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setHrDocsExpiryDate(dateStr);
                                }}
                                onBlur={validation.handleBlur}
                                value={HrDocsExpiryDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                minDate: 'today',
                                defaultDate: 'today',
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                            
                        </Col>

                    
                    </Row>

                    )}
                    
                    <Row>
                        <Col>
                            <div className="text-end">
                            <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={TopLoading}
                                onClick={handleHrDocuments}
                            >
                                Upload
                            </button>
                            </div>
                        </Col>
                    </Row>

                    </CardBody>
                </Card>

                </>} */}

                </Col>

                <Col lg="4">
                    <Card>
                        <CardBody>

                            { SendSms  &&  (
                            <>
                            <Row>
                                <Col xs={12}>
                                    <div className="mb-3">
                                    <Label className="form-label">Sms Subject</Label>
                                    <Input
                                        name="sms_subject"
                                        type="text"
                                        onChange={e => {
                                        setSmsSubject(e.target.value);
                                        }}
                                        value={SmsSubject}
                                    />
                                    </div>
                                </Col>
                            </Row>         
                            </>
                            )}
                            
                        <Row>
                            <Col xs={12}>

                                <div className="mb-3">
                                    <Label className="form-label">Add Notes</Label>
                                    <Input
                                    name="roster_notes"
                                    type="textarea"
                                    onChange={e => {
                                        setStaffNotes(e.target.value);
                                    }}
                                    value={StaffNotes}
                                    />
                                    
                                </div>
                            
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Input
                                    type="checkbox"
                                    name="sendSms"
                                    onChange={(event) => handleSendSms(event)}
                                /> Send Sms
                                <div className="text-end">
                                    <button onClick={addStaffNotes}
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={TopLoading}
                                    >
                                    Add
                                    </button>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div className="order_notes_div">
                            {
                                HrNotesLoading ? <Spinners setLoading={setLoading} />
                                :
                                <ul className="order_notes">
                                    { StaffNotesList.map(activity => (
                                    <li key={activity.id} rel={activity.id} className="note">
                                        <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                        <strong>
                                        {activity.activity_by}
                                        </strong>
                                        </abbr></p>
                                    </li>
                                    ))}
                                </ul>
                            }
                            </div>
                        </Row>
                        <br/><br/>

                        <CardTitle>Hr Notes Acticity</CardTitle>

                        { loggedUser.role == 'admin' || loggedUser.role == 'admin' || loggedUser.role == 'human_resource' ? 
                        <>

                            { loggedUser.role == 'human_resource' || loggedUser.role == 'admin' ? 
                            <>
                                <Row>
                                    <Col xs={12}>

                                        <div className="mb-3">
                                            <Label className="form-label">Add Hr Notes</Label>
                                            <Input
                                            name="roster_notes"
                                            type="textarea"
                                            onChange={e => {
                                                setStaffHrNotes(e.target.value);
                                            }}
                                            value={StaffHrNotes}
                                            />
                                            
                                        </div>
                                    
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                    <div className="text-end">
                                            <button onClick={addStaffHrNotes}
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={TopLoading}
                                            >
                                            Add
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </> : null }

                        </> : null }

                        <Row>
                            <div className="order_notes_div">
                            {
                                NotesLoading ? <Spinners setLoading={setLoading} />
                                :
                                <ul className="order_notes">
                                    { StaffHrNotesList.map(activity => (
                                    <li key={activity.id} rel={activity.id} className="note">
                                        <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                        <strong>
                                        {activity.activity_by}
                                        </strong>
                                        </abbr></p>
                                    </li>
                                    ))}
                                </ul>
                            }
                            </div>
                        </Row>
                    
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}


EditStaff.propTypes = {
    match: PropTypes.any,
};
export default withRouter(EditStaff);