import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map, values } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, RECRUITMENTS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";
import axios from 'axios';

import {Name, RecruitmentStatus } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getEmployds as onGetEmployds,
    updateCandidate as onUpdateCandidate,
    addCandidate as onAddCandidate,
} from "store/actions";

function EmployedLists(){
    //meta title
    document.title="Candidate List | Tacveiha Panel";

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    const [modal, setModal] = useState(false);
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editID, setEditID] = useState('');
    const [status, setStatus ] = useState([]);
    const [roster, setRoster] = useState([]);

    const [questionsValue, setQuestionsValue] = useState([]);
    const [newAnsValue, setNewAnsValue] = useState([]);

    const [DateHired, setDateHired] = useState('');
    const [DateApplied, setDateApplied] = useState('');
    const [FirstMonthBonusDueDate, setFirstMonthBonusDueDate] = useState('');
    const [FirstMonthBonusPaidDate, setFirstMonthBonusPaidDate] = useState('');
    const [ThreeMonthBonusDueDate, setThreeMonthBonusDueDate] = useState('');
    const [ThreeMonthBonusPaidDate, setThreeMonthBonusPaidDate] = useState('');

    const [states, setStates] = useState([]);
    const [activityLog, setActivityLog] = useState([]);
    const [Notes, setNotes] = useState('');

    const statesDropdown = [
        { label: "Select State", value: "0" },
        { label: "New South Wales", value: "New South Wales" },
        { label: "Victoria", value: "Victoria" },
        { label: "Queensland", value: "Queensland" },
        { label: "Western Australia", value: "Western Australia" },
        { label: "South Australia", value: "South Australia" },
        { label: "Tasmania", value: "Tasmania" },
        { label: "Northern Territory Canberra", value: "Northern Territory Canberra" }
    ]

    const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        status: (roster && roster.status) || "",
        candidate_name: (roster && roster.candidate_name) || "",
        candidate_email : (roster && roster.candidate_email) || "",
        referred_by : (roster && roster.referred_by) || "",
        position_applied : (roster && roster.position_applied) || "",
        candidate_mobile_number : (roster && roster.candidate_mobile_number) || "",
        // state : (roster && roster.state) || "",
        date_applied: (roster && roster.date_applied) || "",
        date_hired:(roster && roster.date_hired) || "",
        first_month_referral_bonus_due_date: (roster && roster.first_month_referral_bonus_due_date) || "",
        first_month_referral_bonus_paid_date:(roster && roster.first_month_referral_bonus_paid_date) || "",
        three_month_referral_bonus_due_date: (roster && roster.three_month_referral_bonus_due_date) || "",
        three_month_referral_bonus_paid_date: (roster && roster.three_month_referral_bonus_paid_date) || "",
    },
    validationSchema: Yup.object({
        // status: Yup.string().required("This field is required"),
        candidate_name: Yup.string().required("This field is required"),
        candidate_email : Yup.string().required("This field is required"),
        referred_by : Yup.string().required("This field is required"),
        position_applied : Yup.string().required("This field is required"),
        candidate_mobile_number : Yup.string().required("This field is required"),
        // state : Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
            rtid: roster.rtid,
            status: values.status,
            candidate_name : values.candidate_name,
            candidate_email: values.candidate_email,
            referred_by: values.referred_by,
            position_applied: values.position_applied,
            candidate_mobile_number: values.candidate_mobile_number,
            state: states,
            questionsValue: questionsValue,
            date_hired: DateHired,
            date_applied: DateApplied,
            first_month_referral_bonus_due_date: FirstMonthBonusDueDate,
            first_month_referral_bonus_paid_date: FirstMonthBonusPaidDate,
            three_month_referral_bonus_due_date: ThreeMonthBonusDueDate,
            three_month_referral_bonus_paid_date: ThreeMonthBonusPaidDate
        };

        // console.log(updateUser);

        // update user
         setModalLoading(true);

         axios.post(APP_URL+RECRUITMENTS_PAGE,{'data_for': 'edit_recruitment_add_form','formData': updateUser,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                dispatch(onUpdateCandidate(fetchedData.data));
                toast.success(fetchedData.html, { autoClose: 5000 });
                validation.resetForm();
                setQuestionsValue([]);
                toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      } else {

        // console.log(states);

        if( states == '' ) {
          toast.error("Please select state value", { autoClose: 5000 });
          return;
        }
        const newData = {
            candidate_name : values.candidate_name,
            candidate_email: values.candidate_email,
            referred_by: values.referred_by,
            position_applied: values.position_applied,
            candidate_mobile_number: values.candidate_mobile_number,
            state: states,
            questionsValue: newAnsValue
        };
        // console.log(newData);

         //add staff leave
         setModalLoading(true);

         axios.post(APP_URL+RECRUITMENTS_PAGE,{'data_for': 'recruitment_add_form','formData': newData,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                
                dispatch(onAddCandidate(fetchedData.data));
                toast.success(fetchedData.html, { autoClose: 5000 });
                validation.resetForm();
                setStates([]);
                setNewAnsValue([]);
                toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.RecruitmentsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        employedList: rosterReducer.employeds,
        questions: rosterReducer.questions,
        loading: rosterReducer.loading
      })
  );
  const {
    employedList, questions, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {
    const formData = {
      status : ['2']
    }
    if (employedList && !employedList.length) {
      dispatch(onGetEmployds({'data_for':'get_candidate_list','formData': formData,'user': loggedUser}));
    }
  }, [dispatch]);

  
  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    // console.log(user);
    setRoster({
        rtid: user.rtid,
        status: user.status,
        candidate_name : user.candidate_name,
        candidate_email: user.candidate_email,
        referred_by: user.referred_by,
        position_applied: user.position_applied,
        candidate_mobile_number: user.candidate_mobile_number,
        state: user.state,

    });
    setStates({label: user.state, value: user.state});
    setDateHired(user.date_hired);
    setDateApplied(user.date_applied);
    setFirstMonthBonusDueDate(user.first_month_referral_bonus_due_date);
    setFirstMonthBonusPaidDate(user.first_month_referral_bonus_paid_date)
    setThreeMonthBonusDueDate(user.three_month_referral_bonus_due_date)
    setThreeMonthBonusPaidDate(user.three_month_referral_bonus_paid_date)
    getCandidateDetails(user.rtid);
    setIsEdit(true);
    toggle();
  }

  const getCandidateDetails = (rtid) => {
    setActivityLog([]);
    const formData = {
        rtid : rtid
    }

    //add staff leave
    setModalLoading(true);

    axios.post(APP_URL+RECRUITMENTS_PAGE,{'data_for': 'get_candidates_details','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            setQuestionsValue(fetchedData.questionsValue);
            setActivityLog(fetchedData.activityLog)
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
      })

  }

  useEffect(() => {
    // console.log(questionsValue);
  },[questionsValue]);

  const handleUserClicks = () => {
    setRoster([]);
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Candidate Name",
        accessor: "candidate_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return (
            <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <Name {...cellProps} />
              </Link>
          )
        },
      },
      {
        Header: "Candidate Email",
        accessor: "candidate_email",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Mobile No",
        accessor: "candidate_mobile_number",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Referred BY",
        accessor: "referred_by",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Date Applied",
        accessor: "date_applied",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Position Applied",
        accessor: "position_applied",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "State",
        accessor: "state",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Date Started",
        accessor: "date_hired",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Firt Bonus Due Date",
        accessor: "first_month_referral_bonus_due_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "First Bonus Paid Date",
        accessor: "first_month_referral_bonus_paid_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Second Bonus Due Date( 3 months )",
        accessor: "three_month_referral_bonus_due_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Second Bonus Paid Date( 3 months )",
        accessor: "three_month_referral_bonus_paid_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <RecruitmentStatus {...cellProps} />;
        },
      },
    ],
    []
  )

  // Handle input change
  const handleInputChange = (index, event) => {
    const values = [...questionsValue];
    values[index]['rq_ans'] = event.target.value;
    setQuestionsValue(values);
  }

  const handleQuestChange = (index, event) => {
    const values = [...newAnsValue];
    values[index] = event.target.value;
    setNewAnsValue(values);
  }

  const addNotes = () => {

    if( Notes == '' ) {
      toast.error("Please add notes", { autoClose: 5000 });
      return false;
    }

    const NotesForm = {
        rtid: roster.rtid,
        notes: Notes,
        activity_for: 'recruitment'
    };

    setModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'recruitment_task_add_notes','formData': NotesForm,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setActivityLog(activityLog => [fetchedData.activityLog,...activityLog]);
          setNotes('');
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })

  }

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Recruitment Employed Details" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={employedList}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    isAddBtn={true}
                    btnText='Add Candidates'
                    customPageSize={50}
                    className="custom-header-css"
                    // excelExport={true}
                    // fileName='Add Candidate'
                  />
                }
                  <Modal isOpen={modal} toggle={toggle}  size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}

                      { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
                    </ModalHeader>
                    <ModalBody>
                    <Row>
                        <Col xs={isEdit ? 8 : 12}>
                            <Form
                                onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                                }}
                            >
                                { isEdit && 
                                <Row className="mb-4">
                                    <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                    ></Label>
                                    <Col sm={9}>
                                        <Input
                                            name="status"
                                            type="radio"
                                            onChange={validation.handleChange}
                                            value='1'
                                            checked={validation.values.status == 1 ? true : false }
                                            invalid={
                                                validation.touched.status &&
                                                validation.errors.status
                                                    ? true
                                                    : false
                                            }
                                        /> &nbsp;&nbsp;Active
                                    
                                    
                                        &nbsp;&nbsp;<Input
                                            name="status"
                                            type="radio"
                                            onChange={validation.handleChange}
                                            value='2'
                                            checked={validation.values.status == 2 ? true : false }
                                            invalid={
                                                validation.touched.status &&
                                                validation.errors.status
                                                    ? true
                                                    : false
                                            }
                                        />&nbsp;&nbsp;Employed
                                    
                                        &nbsp;&nbsp;<Input
                                            name="status"
                                            type="radio"
                                            onChange={validation.handleChange}
                                            value='3'
                                            checked={validation.values.status == 3 ? true : false }
                                            invalid={
                                                validation.touched.status &&
                                                validation.errors.status
                                                    ? true
                                                    : false
                                            }
                                        />&nbsp;&nbsp;Rejected
                                        {validation.touched.status &&
                                            validation.errors.status ? (
                                            <FormFeedback type="invalid">
                                            {validation.errors.status}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                </Row>

                                }
                                
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Candidate Name
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                    value={validation.values.candidate_name}
                                    name="candidate_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.candidate_name &&
                                        validation.errors.candidate_name
                                            ? true
                                            : false
                                    }
                                    />
                                    {validation.touched.candidate_name &&
                                        validation.errors.candidate_name ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.candidate_name}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Candidate Email
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        value={validation.values.candidate_email}
                                        name="candidate_email"
                                        type="email"
                                        onChange={validation.handleChange}
                                        invalid={
                                        validation.touched.candidate_email &&
                                        validation.errors.candidate_email
                                            ? true
                                            : false
                                        }
                                    />
                                    {validation.touched.candidate_email &&
                                        validation.errors.candidate_email ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.candidate_email}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Referred By
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                    value={validation.values.referred_by}
                                    name="referred_by"
                                    type="text"
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.referred_by &&
                                        validation.errors.referred_by
                                            ? true
                                            : false
                                    }
                                    />
                                    {validation.touched.referred_by &&
                                        validation.errors.referred_by ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.referred_by}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                    Position Applied and Location
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                    value={validation.values.position_applied}
                                    name="position_applied"
                                    type="text"
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.position_applied &&
                                        validation.errors.position_applied
                                            ? true
                                            : false
                                    }
                                    />
                                    {validation.touched.position_applied &&
                                        validation.errors.position_applied ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.position_applied}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                    Mobile No
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                    value={validation.values.candidate_mobile_number}
                                    name="candidate_mobile_number"
                                    type="text"
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.candidate_mobile_number &&
                                        validation.errors.candidate_mobile_number
                                            ? true
                                            : false
                                    }
                                    />
                                    {validation.touched.candidate_mobile_number &&
                                        validation.errors.candidate_mobile_number ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.candidate_mobile_number}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                    State
                                    </Label>
                                    <Col sm={9}>
                                    <Select
                                    value={states}
                                    name="states"
                                    options={statesDropdown}
                                    onChange={(e) => setStates(e)}
                                    className="select2-selection"
                                    defaultValue={statesDropdown}
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                    />
                                    </Col>
                                </Row>

                                <CardTitle>Questions</CardTitle>
                                {/* { console.log(questionsValue[0].rq_ans) } */}

                                { !!isEdit ?
                                <>

                                { questions.map((maps,i) => (

                                <>
                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                        {maps.question_text} 
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                name="questions[]"
                                                type="textarea"
                                                onChange={(e) => handleInputChange(i,e)}
                                                onBlur={validation.handleBlur}
                                                value={ questionsValue[i] ? questionsValue[i].rq_ans : '' }
                                            />
                                        </Col>
                                    </Row>
                                </>
                                ))
                                }

                                </> 
                                
                                : 
                                <>

                                { questions.map((maps,i) => (

                                <>
                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                        {maps.question_text}
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                name="questions"
                                                type="textarea"
                                                onChange={(e) => handleQuestChange(i,e)}
                                                onBlur={validation.handleBlur}
                                                value={ newAnsValue[i] ? newAnsValue[i]  : '' }
                                            />
                                        </Col>
                                    </Row>
                                </>
                                ))
                                }

                                </>
                                }

                                

                                { isEdit && 
                                <>
                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Date Applied
                                        </Label>
                                        <Col sm={9}>
                                        <InputGroup>
                                                <Flatpickr
                                                    name="date_applied"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setDateApplied(dateStr); //setToDate(dateStr);
                                                    }}
                                                    value={DateApplied}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        },
                                                    }}
                                                    // disabled={ validation.values.date_applied != '' ? true : false }
                                                />
                                            </InputGroup>
                                            
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Date Hired
                                        </Label>
                                        <Col sm={9}>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="date_hired"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                    setDateHired(dateStr); //setToDate(dateStr);
                                                    }}
                                                    value={DateHired}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                            
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            First Month Referral Bonus Due Date
                                        </Label>
                                        <Col sm={9}>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="first_month_referral_bonus_due_date"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setFirstMonthBonusDueDate(dateStr); //setToDate(dateStr);
                                                    }}
                                                    value={FirstMonthBonusDueDate}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                            
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            First Month Referral Bonus Paid Date
                                        </Label>
                                        <Col sm={9}>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="first_month_referral_bonus_paid_date"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setFirstMonthBonusPaidDate(dateStr); //setToDate(dateStr);
                                                    }}
                                                    value={FirstMonthBonusPaidDate}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                            
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Three Month Referra Bonus Due Date
                                        </Label>
                                        <Col sm={9}>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="three_month_referral_bonus_due_date"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setThreeMonthBonusDueDate(dateStr); //setToDate(dateStr);
                                                    }}
                                                    value={ThreeMonthBonusDueDate}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                            
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Three Month Referral Bonus Paid Date
                                        </Label>
                                        <Col sm={9}>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="three_month_referral_bonus_paid_date"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setThreeMonthBonusPaidDate(dateStr); //setToDate(dateStr);
                                                    }}
                                                    value={ThreeMonthBonusPaidDate}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                </>
                                }
                                
                                <Row>
                                <Col>
                                    <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                        disabled={ModalLoading}
                                    >
                                        { !!isEdit ? 'Update' : 'Add' }
                                    </button>
                                    </div>
                                </Col>
                                </Row>

                            </Form>
                        </Col>

                        { isEdit && 
                            <>
                            <Col xs={4}>
                                <Row>
                                <Col xs={12}>
                                    <div className="mb-3">
                                    <Label className="form-label">Add Notes</Label>
                                    <Input
                                        name="notes"
                                        type="textarea"
                                        onChange={e => {
                                        setNotes(e.target.value);
                                        }}
                                        value={Notes}
                                    />
                                    </div>
                                </Col>
                                </Row>

                                <Row>
                                <Col>
                                    <div className="text-end">
                                    <button onClick={addNotes}
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Add
                                    </button>
                                    </div>
                                </Col>
                                </Row>

                                <Row>
                                <div className="order_notes_div">
                                    {
                                    ModalLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <ul className="order_notes">
                                    {activityLog.map(activity => (
                                        <li key={activity.id} rel={activity.id} className="note">
                                        <div dangerouslySetInnerHTML={{__html: activity.activity_for }} className="note_content"></div>
                                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                        <strong>
                                            {activity.activity_by}
                                        </strong>
                                        </abbr></p>
                                        </li>
                                    ))}
                                    </ul>
                                    }
                                </div>
                                </Row>
                            </Col>
                            </>
                            }
                        </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default EmployedLists;