import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, GET_CONTRACTORS } from "helpers/url_helper"
import { toast } from "react-toastify";
import axios from 'axios';

import {Name, Status } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TopProgressBar from "components/Common/TopProgressBar"



function StaffLeavesAdd(){
  //meta title
  document.title="Staff Leaves Add | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const LeaveDropdown = [
    {'label': 'Select Leave Type', 'value': '0'},
    {'label': 'Sick Leave', 'value': 'Sick Leave'},
    {'label': 'Other', 'value': 'Other'},
  ]

  const dispatch = useDispatch()

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [LeaveType, setLeaveType] = useState('');
  const [LeaveStaff, setLeaveStaff] = useState([]);
  const [staffsDropdown, setStaffsDropdown] = useState([]); 


  const [ModalLoading, setModalLoading] = useState(false);
  const [roster, setRoster] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (roster && roster.status) || 0,
      staff_name: (roster && roster.staff_name) || "",
      reason: (roster && roster.reason) || "",
      from_date: (roster && roster.from_date) || "",
      to_date: (roster && roster.to_date) || "",
      leave_type: (roster && roster.leave_type) || "",
    },
    validationSchema: Yup.object({
        status: Yup.string().required("This is required"),
        // staff_name: Yup.string().required("This is required"),
        reason: Yup.string().required("This is required"),
    }),
    onSubmit: values => {

      if( LeaveStaff == '' ) {
        toast.error('Please select staff name', { autoClose: 5000 });
        return;
      }

      if( fromDate == '' ) {
        toast.error('Please select leave from date', { autoClose: 5000 });
        return;
      }

      if( toDate == '' ) {
        toast.error('Please select leave end date', { autoClose: 5000 });
        return;
      }

      if( LeaveType == '' ) {
        toast.error('Please select leave type', { autoClose: 5000 });
        return;
      }
     
        const newData = {
          status: values.status,
          staff_name: LeaveStaff,
          reason: values.reason,
          from_date: fromDate,
          to_date: toDate,
          leave_type: LeaveType,
        }
        

        //add staff leave
        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'add_staff_leave','formData': newData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {

              localStorage.setItem('leave_approved_count', fetchedData.leave_alert_count);
            localStorage.setItem('leave_unapproved_count', fetchedData.leave_alert_approved_count);
              
              toast.success(fetchedData.html, { autoClose: 5000 });
              
              setFromDate('');
              setToDate('');
              setLeaveType({label:'Select leave type', value:'0'});

              validation.resetForm();
              
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
          })
    },
  })

  useEffect(() => {
    setModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for': 'staffs_dropdown','formData': [],'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setStaffsDropdown(fetchedData.staffsDropdown);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
      })
      
  }, [dispatch]);


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  }

  return (
    <React.Fragment>
      { ModalLoading ? <TopProgressBar loading/> : '' }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Staf Leaves" breadcrumbItem="Add Staff Leave" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                      <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        > Status </Label>
                        <Col sm={9}>
                            <Input
                                name="status"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.status &&
                                    validation.errors.status
                                    ? true
                                    : false
                                }
                                checked={validation.values.status == 1 ? true : false }
                                
                            /> &nbsp;&nbsp;Approved
                            &nbsp;&nbsp;<Input
                                name="status"
                                type="radio"
                                onChange={validation.handleChange}
                                value='2'
                                invalid={
                                    validation.touched.status &&
                                    validation.errors.status
                                    ? true
                                    : false
                                }
                                checked={validation.values.status == 2 ? true : false }
                            
                            />&nbsp;&nbsp;Processed
                            &nbsp;&nbsp;<Input
                                name="status"
                                type="radio"
                                onChange={validation.handleChange}
                                value='0'
                                invalid={
                                    validation.touched.status &&
                                    validation.errors.status
                                    ? true
                                    : false
                                }
                                checked={validation.values.status == 0 ? true : false }
                            
                            />&nbsp;&nbsp;Disapproved
                            
                            {validation.touched.status &&
                            validation.errors.status ? (
                            <FormFeedback type="invalid">
                            {validation.errors.status}
                            </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Staff Name
                          </Label>
                          <Col sm={9}>

                          <Select
                            value={LeaveStaff}
                            name="staff_name"
                            options={staffsDropdown}
                            onChange={(e) => setLeaveStaff(e)}
                            className="select2-selection"
                            defaultValue={staffsDropdown}
                            styles={{
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />

                          {/* <Input
                              name="staff_name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.staff_name}
                              invalid={
                              validation.touched.staff_name &&
                                  validation.errors.staff_name
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.staff_name &&
                              validation.errors.staff_name ? (
                              <FormFeedback type="invalid">
                              {validation.errors.staff_name}
                              </FormFeedback>
                          ) : null} */}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Leave Reason
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="reason"
                              type="textarea"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.reason}
                              invalid={
                              validation.touched.reason &&
                                  validation.errors.reason
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.reason &&
                              validation.errors.reason ? (
                              <FormFeedback type="invalid">
                              {validation.errors.reason}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              From Date
                          </Label>
                          <Col sm={9}>
                          <InputGroup>
                            <Flatpickr
                                name="from_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setFromDate(dateStr);
                                }}
                                onBlur={validation.handleBlur}
                                value={fromDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              End Date
                          </Label>
                          <Col sm={9}>
                          <InputGroup>
                            <Flatpickr
                                name="to_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setToDate(dateStr);
                                }}
                                onBlur={validation.handleBlur}
                                value={toDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="mb-4">
                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      >
                      Leave Type
                      </Label>
                      <Col sm={9}>
                        <Select
                            value={LeaveType}
                            name="leave_type"
                            options={LeaveDropdown}
                            onChange={(e) => setLeaveType(e)}
                            className="select2-selection"
                            defaultValue={LeaveDropdown}
                            styles={{
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                      </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className="text-end">
                            <button
                            type="submit"
                            className="btn btn-info"
                            >
                            Add
                            </button>
                        </div>
                        

                        </Col>
                    </Row>
                </Form>
                    
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default StaffLeavesAdd;